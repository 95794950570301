<template>
  <div id="sensor-view">
    <div class="bg-container1" :class="{ 't-bg': showChart }">
      <div v-show="showChart" class="t-head jac">
        <h1>传感器大屏</h1>
        <full-screen
          v-show="showChart"
          style="position: absolute; right: 20px"
        ></full-screen>
      </div>
      <div class="main-content">
        <div class="tl p15">
          <div
            v-if="company_id != '2581597'"
            @click="changeChart"
            class="button"
          >
            切换图表
          </div>
        </div>
        <div
          class="card-list"
          v-if="showChart"
          v-loading="loading"
          element-loading-text="大屏加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0)"
        >
          <div class="card-list" v-if="company_id == '2581597'">
            <div
              class="card-item2"
              v-for="(item, key, index) in list"
              :key="index"
            >
              <dv-border-box-10>
                <div
                  style="
                    height: 160px;
                    border-bottom: 2px solid #f6f5ec;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div class="img-box">
                    <img
                      style="height: 40px; color: white"
                      src="./img/qixiangzhan.png"
                    />
                  </div>
                </div>
                <div
                  style="
                    color: white;
                    margin-top: -40px;
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 20px;
                  "
                >
                  {{ key }}
                </div>
                <div style="color: white; padding: 20px; font-size: 14px">
                  <div
                    v-for="(item1, index1) in item"
                    :key="index1"
                    style="
                      display: flex;
                      justify-content: space-between;
                      margin-bottom: 20px;
                      font-size: 18px;
                    "
                    class="font-f"
                  >
                    <div>
                      {{ item1.name.replace(key, "") }}
                    </div>
                    <div>
                      <span style="font-weight: 500">
                        {{ item1.value }}
                      </span>
                      <span v-if="item1.unit">({{ item1.unit }})</span>
                    </div>
                  </div>
                </div>
                <div class="p10 tr">
                  <div
                    class="button"
                    @click="(showSensorList = true), (curIndex = key)"
                  >
                    历史数据
                  </div>
                </div>
              </dv-border-box-10>
            </div>
          </div>

          <div class="card-list" v-else>
            <div
              class="card-item"
              v-for="(item, key, index) in list"
              :key="index"
            >
              <dv-border-box-10>
                <div class="card-item-title">
                  <div v-if="key && key != 'null'">{{ key }}</div>
                </div>
                <dv-decoration-10 style="width: 100%; height: 5px" />
                <!--              <div style="display: flex;align-content: flex-start;flex-flow: row wrap;">-->
                <!--                <div v-for="(chartItem,chartIndex) in item" :key="chartIndex" style="flex: 0 0 33%;">-->
                <!--                 <ve-gauge :data="chartItem.chartData" :settings="chartItem.chartSettings"></ve-gauge>-->
                <!--                  <div style="margin-top: -80px;font-size: 18px">-->
                <!--                    {{chartItem.chartData.rows[0].type.replace(index === 0?'猪场气象站':'办公区气象站','')}} ({{chartItem.unit}})-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </div>-->
                <div class="yqlist">
                  <ul class="clearfix">
                    <li
                      v-for="(chartItem, chartIndex) in item"
                      :key="chartIndex"
                    >
                      <div class="yq" id="yq">
                        {{ chartItem.value.toFixed(1) }}
                      </div>
                      <!--                              <div v-else class="yq" id="yq">-->
                      <!--                                <div class="wrapper">-->
                      <!--                                  <div class="loader">-->
                      <!--                                    <div class="inner"></div>-->
                      <!--                                  </div>-->
                      <!--                                </div>-->
                      <!--                              </div>-->
                      <span @click="openHistoryData(chartItem)" class="hover"
                        >{{ chartItem.name.replace(key, "") }}
                        <span class="hover" v-if="chartItem.unit"
                          >({{ chartItem.unit }})</span
                        ></span
                      >
                    </li>
                  </ul>
                </div>
              </dv-border-box-10>
            </div>
          </div>
        </div>
        <div
          class="card-list"
          v-else
          v-loading="loading"
          element-loading-text="数据加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0)"
        >
          <div
            class="card-item1"
            v-for="(item, key, index) in list"
            :key="index"
          >
            <div
              style="
                height: 160px;
                border-bottom: 2px solid #f6f5ec;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="img-box">
                <img style="height: 40px" src="./img/qixiangzhan.png" />
              </div>
            </div>
            <div
              style="
                color: #464547;
                margin-top: -40px;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 20px;
              "
              v-if="key && key != 'null'"
            >
              {{ key }}
            </div>
            <div style="color: #77787b; padding: 20px; font-size: 14px">
              <div
                v-for="(item1, index1) in item"
                :key="index1"
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 20px;
                  font-size: 18px;
                "
                class="font-f"
              >
                <div>
                  {{ item1.name.replace(key, "") }}
                </div>
                <div>
                  <span style="font-weight: 500">
                    {{ item1.value }}
                  </span>
                  <span v-if="item1.unit">({{ item1.unit }})</span>
                </div>
              </div>
            </div>
            <!--            <div class="p10 tr">-->
            <!--              <el-button type="primary" plain @click="openHistoryData(item)">历史数据</el-button>-->
            <!--            </div>-->
          </div>
        </div>
        <!--        <div class="i-page disib mt20" v-show="!loading || query.page != 1">-->
        <!--          <el-pagination-->
        <!--                  background-->
        <!--                  :page-size="10"-->
        <!--                  layout="total,prev, pager, next"-->
        <!--                  :total="listTotal"-->
        <!--                  :current-page="query.page"-->
        <!--                  @current-change="currentChange"-->
        <!--          >-->
        <!--          </el-pagination>-->
        <!--        </div>-->
      </div>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogTableVisible"
      v-loading="isDownLoading"
    >
      <div style="margin-bottom: 10px">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
        <el-button
          type="primary"
          size="small"
          @click="onSearch"
          style="margin-left: 10px"
          >筛选</el-button
        >
        <el-button
          v-if="buttonFlag"
          type="success"
          size="small"
          @click="onChangeChart"
          >切换图表</el-button
        >
        <el-button
          v-if="buttonFlag"
          type="success"
          size="small"
          @click="exportExcel"
          >导出数据</el-button
        >
      </div>
      <transition name="el-fade-in-linear">
        <div v-show="lineChartFlag">
          <ve-line
            ref="lineChart"
            :loading="lineChartLoading"
            :data="lineChartData"
            :extend="lineChartExtend"
            :settings="lineChartSettings"
          ></ve-line>
        </div>
      </transition>
      <transition name="el-fade-in-linear">
        <div v-show="!lineChartFlag">
          <el-table
            :data="gridData"
            border
            fit
            height="800"
            v-loading="isSearching"
          >
            <el-table-column
              align="center"
              property="data"
              label="值"
            ></el-table-column>
            <el-table-column
              align="center"
              property="time"
              label="时间"
            ></el-table-column>
          </el-table>
          <div class="pagination-container" v-show="pageFlag">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="hisQuery.page"
              :page-size="50"
              layout="total, prev, pager, next"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </transition>
    </el-dialog>

    <el-dialog :title="curIndex" :visible.sync="showSensorList">
      <transition name="el-fade-in-linear">
        <div>
          <el-table :data="list[curIndex]" border fit height="800">
            <el-table-column align="center" label="名称">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{
                  scope.row.name.replace(curIndex, "")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="value"
              label="值"
            ></el-table-column>
            <el-table-column
              align="center"
              property="unit"
              label="单位"
            ></el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="openHistoryData(scope.row)"
                  >查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </transition>
    </el-dialog>
  </div>
</template>

<script>
import { pickerOptions, seriesOption } from "./optionData";
// import sensor from "@/api/sensor.js";
import { deepClone, local } from "@/util/util";
import bus from "@/util/bus";
import "v-charts/lib/style.css";
import api from "../../../util/extra-api";
export default {
  data() {
    this.lineChartExtend = {
      "xAxis.0.axisLabel.rotate": 45,
    };
    this.lineChartSettings = {
      labelMap: {
        time: "时间",
        value: "值",
      },
    };
    return {
      isDownLoading: false,
      isSearching: false,
      showChart: true,
      currentPage: 1,
      ListcurrentPage: 1,
      page: 1,
      listTotal: 0,
      total: null,
      loading: false,
      pageFlag: false,
      sensorId: null,
      sensorList: [],
      gridData: [],
      dateRange: "",
      title: "",
      buttonFlag: false,
      lineChartData: {},
      lineChartFlag: false,
      lineChartLoading: false,
      dialogTableVisible: false,
      pickerOptions,
      seriesOption,
      query: {
        page: 1,
        companyId: null,
      },
      hisQuery: {
        id: null,
        page: 1,
        companyId: null,
        limit: 50,
        startTime: "",
        endTime: "",
      },
      list: [],
      company_id: null,
      showSensorList: false,
      curIndex: null,
    };
  },
  watch: {
    "lineChartData.rows"(v) {
      this.$nextTick((_) => {
        this.$refs[`lineChart`].echarts.resize();
      });
    },
    dialogTableVisible(v) {
      if (!v) {
        this.hisQuery.startTime = "";
        this.hisQuery.endTime = "";
        this.hisQuery.page = 1;
      }
    },
  },
  computed: {},
  created() {},
  mounted() {
    this.company_id = local.get("company_id");
    this.initData();
  },
  methods: {
    exportExcel() {
      this.isDownLoading = true;
      api
        .fileget(`/v1/wx/device/export`, {
          id: this.sensorId,
          startTime: this.dateRange[0],
          endTime: this.dateRange[1],
          companyId: localStorage.getItem("company_id"),
        })
        .then((res) => {
          this.isDownLoading = false;
        })
        .catch((err) => {
          this.isDownLoading = false;
        });
    },
    changeChart() {
      this.showChart = this.showChart ? false : true;
    },
    currentChange(page) {
      this.query.page = page;
      this.initData();
    },
    initData() {
      this.loading = true;
      this.query.companyId = localStorage.getItem("company_id");
      api.get("/v1/wx/device/sensorChart1", this.query).then((response) => {
        const data = response.data;
        this.list = data.groupBy((m) => m.showType);
        let sensorArr = [];
        data.forEach((item) => {
          let series = deepClone(this.seriesOption);
          series.min = item.minValue;
          series.max = item.maxValue;
          sensorArr.push({
            unit: item.unit,
            showType: item.showType,
            id: item.id,
            name: item.name,
            chartData: {
              columns: ["type", "value"],
              rows: [{ type: item.name, value: item.value }],
            },
            chartSettings: {
              dataName: {
                [item.name]: item.unit,
              },
              seriesMap: {
                [item.name]: series,
              },
            },
          });
        });
        this.listTotal = response.total;
        this.sensorList[0] = sensorArr.filter((m) => m.showType == "猪场");
        this.sensorList[1] = sensorArr.filter((m) => m.showType == "办公区");
        this.loading = false;
      });
    },
    openHistoryData(item) {
      const { id, name } = item;
      this.title = `历史数据[${name}]`;
      this.dateRange = [];
      this.page = 1;
      this.sensorId = id;
      this.buttonFlag = false;
      this.lineChartFlag = false;
      this.lineChartData = {};
      this.hisQuery.id = id;
      this.hisQuery.companyId = localStorage.getItem("company_id");
      api.get("/v1/wx/device/sensorHisData", this.hisQuery).then((response) => {
        const data = response.data;
        this.gridData = data.data.map((m) => {
          return {
            data: m.value + m.unit,
            time: m.timeDate,
          };
        });
        this.pageFlag = false;
        this.dialogTableVisible = true;
        this.total = data.total;
        this.page = 1;
        this.pageFlag = true;
      });
    },
    onSearch() {
      if (this.dateRange && this.dateRange.length > 0) {
        this.isSearching = true;
        this.hisQuery.page = 1;
        this.pageFlag = true;
        this.buttonFlag = true;
        this.hisQuery.startTime = this.dateRange[0];
        this.hisQuery.endTime = this.dateRange[1];
        api
          .get("/v1/wx/device/sensorHisData", this.hisQuery)
          .then((response) => {
            const data = response.data;
            this.gridData = data.data.map((m) => {
              return {
                data: m.value + m.unit,
                time: m.timeDate,
              };
            });
            this.total = data.total;
            this.isSearching = false;
          })
          .catch((err) => {
            this.isSearching = false;
          });
        // sensor
        //   .get(this.sensorId, {
        //     opt: 2,
        //     start_time: this.dateRange[0],
        //     end_time: this.dateRange[1],
        //     page: this.page
        //   })
        //   .then(response => {
        //     const data = response.data;
        //     this.gridData = data.results;
        //     this.total = data.total;
        //   });
      } else {
        this.$message({
          message: "请先选择时间区间",
          type: "warning",
        });
      }
    },
    handleCurrentChange(val) {
      this.hisQuery.page = val;
      api.get("/v1/wx/device/sensorHisData", this.hisQuery).then((response) => {
        const data = response.data;
        this.gridData = data.data.map((m) => {
          return {
            data: m.value + m.unit,
            time: m.timeDate,
          };
        });
        this.total = data.total;
      });
      // sensor
      //   .get(this.sensorId, {
      //     opt: 2,
      //     start_time: this.dateRange[0],
      //     end_time: this.dateRange[1],
      //     page: this.page
      //   })
      //   .then(response => {
      //     const data = response.data;
      //     this.gridData = data.results;
      //     this.total = data.total;
      //   });
    },
    onChangeChart() {
      this.lineChartFlag = !this.lineChartFlag;

      if (this.lineChartFlag) {
        this.lineChartLoading = true;
        api
          .get("/v1/wx/device/sensorHisData", this.hisQuery)
          .then((response) => {
            const data = response.data.data;
            this.lineChartData = {
              columns: ["timeDate", "value"],
              rows: data,
            };
            // this.lineChartData = {
            //   columns: ["time", "data"],
            //   rows: data
            // };
            this.lineChartLoading = false;
          });
        // sensor
        //   .get(this.sensorId, {
        //     opt: 3,
        //     start_time: this.dateRange[0],
        //     end_time: this.dateRange[1]
        //   })
        //   .then(response => {
        //     const data = response.data;
        //     this.lineChartData = {
        //       columns: ["time", "value"],
        //       rows: data
        //     };
        //     this.lineChartLoading = false;
        //   });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#sensor-view {
  width: 100%;
  min-height: 100vh;
  border-top: 2px solid #d3d7d4;
  color: #fff;
  .t-bg {
    background-image: url("./img/bg.png");
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    background-color: #030409;
  }
  .bg-container1 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .main-content {
      padding: 0 30px;
    }
    .card-list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .card-item {
        flex: 1;
        width: 48%;
        min-width: 48%;
        max-width: 48%;
        /*height: 400px;*/
        margin-bottom: 10px;
        .card-item-title {
          padding: 10px 20px;
          background: transparent;
          text-align: center;
          font-size: 24px;
          color: #fff;
          font-family: Helvetica, "Hiragino Sans GB", "Microsoft Yahei",
            "微软雅黑", Arial, sans-serif;
          cursor: pointer;
        }
        .card-item-content {
          padding: 20px 10px;
        }
      }
      .card-item1 {
        flex: 1;
        width: 32%;
        min-width: 32%;
        max-width: 32%;
        /*height: 400px;*/
        margin-bottom: 10px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 5px 5px 5px #d3d7d4;
        .img-box {
          height: 70px;
          width: 70px;
          background-color: #7bbfea;
          border-radius: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .card-item-content {
          padding: 20px 10px;
        }
      }
      .card-item2 {
        flex: 1;
        width: 32%;
        min-width: 32%;
        max-width: 32%;
        /*height: 400px;*/
        margin-bottom: 10px;
        background-color: #0c274b;
        border-radius: 10px;
        box-shadow: 5px 5px 5px #1694c1;
        .img-box {
          height: 70px;
          width: 70px;
          background-color: #7bbfea;
          border-radius: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .card-item-content {
          padding: 20px 10px;
        }
      }
    }
  }
}

.font-f {
  font-family: Helvetica, "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑",
    Arial, sans-serif;
}
.el-date-editor--daterange.el-input__inner {
  width: 500px !important;
}
::-webkit-scrollbar {
  display: none;
} /* Chrome Safari */

.clearfix:after,
.clearfix:before {
  display: table;
  content: " ";
}
.clearfix:after {
  clear: both;
}
.yqlist ul {
  width: 100%;
}
.yqlist li {
  float: left;
  min-width: 33%;
  max-width: 100%;
  padding: 10px 0;
  text-align: center;
}
.yq {
  width: 230px;
  height: 230px;
  margin: 0 auto 5px auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-family: electronicFont;
  color: #b2d235;
  font-weight: bold;
}
.yqlist li span {
  opacity: 0.9;
  font-size: 16px;
  color: white;
}

.yq:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: url(./img/img1.png) center center;
  border-radius: 100px;
  background-size: 100% 100%;
  opacity: 0.5;
  left: 0;
  top: 0;
  animation: myfirst2 15s infinite linear;
}

.yq:after {
  position: absolute;
  width: 86%;
  background: url(./img/img2.png) center center;
  border-radius: 100px;
  background-size: 100% 100%;
  opacity: 0.5;
  height: 86%;
  content: "";
  left: 7%;
  top: 7%;
  animation: myfirst 15s infinite linear;
}
@keyframes myfirst {
  to {
    transform: rotate(-360deg);
  }
}
@keyframes myfirst2 {
  to {
    transform: rotate(360deg);
  }
}
$color: #2194e0;
@keyframes sheen {
  0% {
    transform: skewY(-45deg) translateX(0);
  }
  100% {
    transform: skewY(-45deg) translateX(12.5em);
  }
}
.button {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  color: #33a3dc;
  border: 2px solid $color;
  font-size: 16px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }
  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}
.hover {
  cursor: pointer;
  &:hover {
    cursor: pointer;
    color: $color;
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}

.wrapper {
  width: 150px;
  height: 30px;
  background: #2c2c2c;
  border-radius: 30px;
  position: relative;
  /*top: 130px;*/
  margin: 0 auto;
  transform: rotate(-90deg) scale(0.85);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -3px 2px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.4),
    0 0 5px rgba(0, 0, 0, 0.4), inset 0 -15px 10px rgba(0, 0, 0, 0.25),
    0 0 100px 50px #252525;
}
.wrapper:before {
  content: "";
  position: absolute;
  background: inherit;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  left: -10px;
  top: 50%;
  margin-top: -22px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -3px 2px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.4),
    0 0 5px rgba(0, 0, 0, 0.4), inset 0 -15px 10px rgba(0, 0, 0, 0.25);
}
.wrapper:after {
  content: "";
  height: 100%;
  width: 10px;
  background: #2c2c2c;
  position: absolute;
  left: 28px;
  top: 0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -3px 2px rgba(0, 0, 0, 0.3), 0 5px 2px rgba(0, 0, 0, 0.2),
    inset 0 -15px 0px rgba(0, 0, 0, 0.25);
}
.loader {
  width: 95%;
  height: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  background: #404040;
  border-radius: inherit;
  z-index: 100;
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.4),
    inset 0 3px 3px rgba(0, 0, 0, 0.5), inset 0 -5px 5px rgba(0, 0, 0, 0.4),
    0 1px 0 rgba(255, 255, 255, 0.1);
}
.inner {
  width: 0%;
  height: 100%;
  background: #2b8095;
  background: -webkit-linear-gradient(
    left,
    #237686,
    #34476f,
    #5b426c,
    #965666,
    #ba835c,
    #d0c746
  );
  background: linear-gradient(
    to right,
    #237686,
    #34476f,
    #5b426c,
    #965666,
    #ba835c,
    #d0c746
  );
  animation: loader 5s ease-out infinite;
  border-radius: inherit;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3),
    inset 0 8px 1px rgba(255, 255, 255, 0.1),
    inset 0 -2px 1px rgba(255, 255, 255, 0.1);
}
.inner:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #2b8095;
  background: linear-gradient(#63b5c0, #146a77);
  left: -3px;
  top: 50%;
  margin-top: -15px;
  box-shadow: inset 0 2px 3px rgba(255, 255, 255, 0.2),
    inset 0 -4px 2px rgba(255, 255, 255, 0.1);
}
.inner:before {
  content: "";
  position: absolute;
  height: 100%;
  background: -webkit-linear-gradient(left, #2b8095, rgba(64, 64, 64, 0));
  background: linear-gradient(to right, #2b8095, rgba(64, 64, 64, 0));
  width: 20px;
  top: 0;
  left: 25px;
  z-index: 200;
}
/*
.loader {
  content: "";
  height: 50%;
  width: 95%;
  position: absolute;
  margin-top: -8px;
  position: absolute;
  left: 0;
  border-radius: inherit;
  background: #404040;
  top: 0;
  left: 0;
  z-index: -1;
}

.inner:after {
  content: "";
 	width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  left: -3px;
  background: inherit;
  top: 50%;
  margin-top: -15px;
}
*/

@keyframes loader {
  100% {
    width: 75%;
  }
}
</style>
